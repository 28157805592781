import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Header } from "../../Header/Header"
import { Footer } from "../../../Footer/Footer"
import { SideBar } from "../../SideBar/SideBar"
import { useAuthState } from "../../../../Helper/Context/context"
import ViewPopup from "../../CommonPopUp/ViewPopup"
import { DistrictDDL } from "../../../../Components/CommonDDL/DistrictDDL"
import { GenderDDL } from "../../../../Components/CommonDDL/GenderDDL"
import { ReceivedGrievanceExportGetAPI, ReceivedGrievanceGetAPI } from "../../../../Helper/Redux/DashboardSlice/ReceivedGrievanceSlice"
import { useDispatch, useSelector } from "react-redux"
import { Pegination } from "../../../../Components/Pagination/Pagination"
import UploadReport from "./UploadReport"
import ViewURL from "../../../../Components/ViewURL/ViewURL"
import { Loading } from "../../../../Helper/Loader/LogoLoader"
import { t } from "i18next"
import moment from "moment"
import { ActionReportUploadExportData } from "./ActionReportUploadExportData"

export const ActionReportUpload = () => {

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID, IndicatorGrievanceAreaID } = userDetails

    const [ShowUploadFilePopup, setShowUploadFilePopup] = useState(false)
    const [ShowViewPopup, setShowViewPopup] = useState(false)

    const [searchparams] = useSearchParams()
    const screenname = searchparams.get('screenname')
    const Flag = searchparams.get('Flag')
    const DDLFinantialYearID = searchparams.get('DDLFinantialYearID')
    const DDLMonthID = searchparams.get('DDLMonthID')

    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [ShowModal, setShowModal] = useState(false)
    const [ReceivedDate, setReceivedDate] = useState('')
    const [IsSearch, setIsSearch] = useState(false)
    const [IsPost, setIsPost] = useState(false)

    // Pagination
    const [IsClear, setIsClear] = useState(false)
    const [PerPageCount, setPerPageCount] = useState(10)
    const [From, setFrom] = useState(1)
    const [To, setTo] = useState(10)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [isopen, setIsOpen] = useState(false);

    const [ModalData, setModalData] = useState({
        Flag: '',
        RowData: ''
    })
    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: sessionStorage.getItem('LanguageChange')
    })
    const [DistrictDDLID, setDistrictDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [GrievanceTypeID, setGrievanceTypeID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const handleShowView = (rowData, t_GrievanceRegistrationID) => {
        setShowViewPopup(!ShowViewPopup)
        setModalData({
            Flag: '',
            RowData: rowData ? rowData : '',
            t_GrievanceRegistrationID: t_GrievanceRegistrationID
        })
    }

    const handleUploadFilePhoto = (rowData, t_GrievanceRegistrationID) => {
        setShowUploadFilePopup(!ShowUploadFilePopup)
        setModalData({
            Flag: '',
            RowData: rowData ? rowData : '',
            t_GrievanceRegistrationID: t_GrievanceRegistrationID
        })
    }
    const handleShowImage = (img) => {
        setshowImagePopup(true)
        setImagePopUpData(img)
    }

    const handleSearch = () => {
        setIsSearch(!IsSearch)
    }

    const handleClear = () => {
        setIsClear(!IsClear)
        setReceivedDate('')
        setDistrictDDLID({
            ...DistrictDDLID,
            ID: 0,
            Label: 'Select...'
        })
        setGrievanceTypeID({
            ...GrievanceTypeID,
            ID: 0,
            Label: 'Select...'
        })

    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }

    useEffect(() => {
        const data = {
            M_FinancialYearID: DDLFinantialYearID,
            M_MonthID: DDLMonthID,
            GrievanceDate: ReceivedDate,
            M_IndicatorGrievanceAreaID: IndicatorGrievanceAreaID,
            M_DistrictID: 0,
            M_TalukaID: 0,
            M_IndicatorGrievanceTypeID: GrievanceTypeID.ID,
            M_StatusID: 0,
            ShowBy: "All",
            Flag: Flag,
            Language: Language.Label,
            M_UserID: UserID,
            FromTop: From,
            ToTop: To,
            token: token,

        }
        { Language.Label != null && dispatch(ReceivedGrievanceGetAPI({ data })) }

    }, [Language.Label, IsClear, IsSearch, IsPost, To, DistrictDDLID.ID, GrievanceTypeID.ID])

    const { TableData, isLoading } = useSelector(state => state.ReceivedGrievanceGetData)

    useEffect(() => {
        const data = {
            M_FinancialYearID: DDLFinantialYearID,
            M_MonthID: DDLMonthID,
            GrievanceDate: ReceivedDate,
            M_IndicatorGrievanceAreaID: IndicatorGrievanceAreaID,
            M_DistrictID: 0,
            M_TalukaID: 0,
            M_IndicatorGrievanceTypeID: GrievanceTypeID.ID,
            M_StatusID: 0,
            ShowBy: "All",
            Flag: Flag,
            Language: Language.Label,
            M_UserID: UserID,
            FromTop: From,
            ToTop: '99999',
            token: token,
        }
        { Language.Label != null && dispatch(ReceivedGrievanceExportGetAPI({ data })) }

    }, [Language.Label, DDLFinantialYearID, DDLMonthID, IsClear, IsSearch, IsPost, To, DistrictDDLID.ID, GrievanceTypeID.ID])

    const { ExportTableData, isExportLoading } = useSelector(state => state.ReceivedGrievanceExportGetData)

    return (
        <>
            {isLoading && <Loading />}
            <Header Language={Language}
                setLanguage={setLanguage} />
            {/* <SideBar active="MainDashboard" /> */}
            <div id="wrapper" className="d-flex justify-content-center">
                <SideBar active="MainDashboard" />
                <div className="main-content user_dashboard d-flex flex-column body-scroll px-0 px-lg-3" id='content' >
                    <div className="row ">

                        <div className="col-12 " style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span className="text-black fw-bold ps-2 pt-1">
                                {/* {t('DirectorDashboard.Dashboard')} */}
                            </span>

                        </div>
                    </div>

                    <div className="container-fluid px-3 shadow bg-white" style={{ border: '4px solid #dddcdc' }}>
                        <>
                            <div className="row pt-3">
                                <div className="col-8">
                                    <h6 className="text-black fw-bold h7 pt-0 pb-2">
                                        {screenname}
                                    </h6>
                                </div>
                                <div className="col-4 mt-2">
                                    {
                                        isExportLoading ?

                                            <button type="button" className="btn btn-success green-btn-color float-end mr-1 mb-1 ml-1" disabled>
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />Loading</button>
                                            :
                                            //  ExportTableData && ExportTableData.length > 0 &&
                                            <ActionReportUploadExportData
                                                ExcelData={ExportTableData}
                                                name={screenname}
                                                screenname={screenname}
                                                handleShowView={handleShowView}
                                                ModalData={ModalData}
                                                RoleID={RoleID}
                                            />
                                    }
                                </div>
                            </div>

                            <div className="row py-3 py-lg-4">

                                <div className="col-12 col-lg-3 py-2 py-lg-0">

                                    {
                                        screenname == 'Resolved Grievance' ?


                                            <span className="all_lable_text">
                                                {t('DirectorDashboard.ResolvedDate')}
                                            </span>
                                            :
                                            <span className="all_lable_text">
                                                {t('DirectorDashboard.SiteVisitDate')}
                                            </span>
                                    }

                                    <input
                                        type="date"
                                        className="form-control my-1"
                                        name="letterSorting"
                                        id="letterSorting"
                                        placeholder=""
                                        value={ReceivedDate}
                                        onChange={(e) => setReceivedDate(e.target.value)}
                                    />
                                </div>

                                {/* <div className="col-12 col-lg-3 py-2 py-lg-0">
                                    <span className="all_lable_text">
                                        {t('DirectorDashboard.DistrictName')}
                                    </span>
                                    <DistrictDDL
                                        DistrictDDLID={DistrictDDLID}
                                        setDistrictDDLID={setDistrictDDLID}
                                        Language={Language}
                                        M_IndicatorGrievanceAreaID={IndicatorGrievanceAreaID}
                                        UserID={UserID}
                                    />
                                </div> */}

                                <div className="col-12 col-lg-3 py-2 py-lg-0">
                                    <span className="all_lable_text">
                                        {t('DirectorDashboard.GrievanceType')}
                                    </span>
                                    <GenderDDL
                                        GenderDDLID={GrievanceTypeID}
                                        setGenderDDLID={setGrievanceTypeID}
                                        Language={Language}
                                        GroupID='3'
                                        UserID={UserID}
                                    />
                                </div>

                                <div className="col-12 col-md-6 col-lg-3 pt-2 mt-3  ">
                                    <button type="button" className="btn btn-outline-dark border-2 mx-2 button-size"
                                        onClick={handleSearch}
                                    >
                                        Search</button>

                                    <button type="button" className="btn btn-danger border-2 button-size"
                                        onClick={handleClear}
                                    >Clear</button>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive p-0">
                                        <table className="table table-bordered w-100">
                                            <thead>
                                                <tr className="bg-dark">
                                                    <th className="">{t('GridHeading.Sr_No')}</th>
                                                    {
                                                        screenname == "Resolved Grievance" ?
                                                            <th className="">{t('DirectorDashboard.ResolvedDate')}</th>
                                                            :
                                                            <th className="">{t('DirectorDashboard.SiteVisitDate')}</th>
                                                    }


                                                    {/* {
                                                        RoleID == 6 ? */}
                                                            <>
                                                            <th className=" ps-2">{t('DirectorDashboard.DistrictName')} / {t('DirectorDashboard.MunicipalCorporation')}</th>
                                                                <th className=" ps-2">{t('DirectorDashboard.TalukaName')}</th>
                                                                {/* <th className=" ps-2">Village Name</th> */}
                                                            </>
                                                            {/* :
                                                            <>
                                                                <th className=" ps-2">{t('DirectorDashboard.MunicipalCorporation')}</th>
                                                                <th className=" ps-2">{t('DirectorDashboard.DistrictName')}</th>
                                                            </>
                                                    } */}

                                                    <th className=" ps-2">{t('DirectorDashboard.GrievanceType')}</th>
                                                    <th className=" ps-2 text-center">{t('DirectorDashboard.GrievanceDetails')}</th>
                                                    <th className=" text-center ">{t('DirectorDashboard.Photo')}</th>

                                                    {
                                                        screenname == 'Resolved Grievance' ?
                                                            <th className=" ps-2 text-center">{t('DirectorDashboard.Report')} </th>
                                                            : screenname == 'Completed Site Visit' ?
                                                                <th className=" ps-2 text-center">{t('DirectorDashboard.Action')}</th>
                                                                :
                                                                <th className=" ps-2 text-center">{t('DirectorDashboard.Action')}</th>

                                                    }

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    TableData && TableData.length > 0 ?
                                                        TableData.map((i, index) => {
                                                            return (
                                                                <tr key={index}>

                                                                    <td className="w-7">{i?.rowNum}</td>

                                                                    {
                                                                        screenname == "Resolved Grievance" ?
                                                                            <td>{i.actionReportDate ? moment(i.actionReportDate).format('DD-MM-YYYY') : '-'}</td>
                                                                            :
                                                                            <td>{i.visitCompletedDate ? moment(i.visitCompletedDate).format('DD-MM-YYYY') : '-'}</td>
                                                                    }

                                                                    {/* {
                                                                        RoleID == 6 ? */}
                                                                            <>
                                                                                <td>{i?.districtName}</td>
                                                                                <td>{i?.talukaName}</td>
                                                                                {/* <td>{i?.villageName}</td> */}
                                                                            </>
                                                                            {/* :
                                                                            <>

                                                                                <td>{i?.districtName}</td>
                                                                                <td>-</td>
                                                                            </>
                                                                    } */}

                                                                    <td>{i?.m_IndicatorGrievanceType}</td>
                                                                    <td className="text-center">
                                                                        {/* <span className="view_button"
                                                                            onClick={() => handleShowView(i, i.t_GrievanceRegistrationID)}
                                                                        >view</span> */}
                                                                        <span className="view_button"
                                                                            data-bs-toggle="tooltip" data-bs-placement="bottom"
                                                                            title="View Grievance Details"
                                                                            onClick={() => handleShowView(i, i.t_GrievanceRegistrationID)}>
                                                                            <i class="fa-solid fa-eye"></i>
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <span className="open_button"
                                                                            onClick={() => (
                                                                                handleShowImage(i.visitPhoto)
                                                                            )}
                                                                        >
                                                                            <i class="fa-solid fa-image"></i>
                                                                        </span>
                                                                    </td>

                                                                    {
                                                                        screenname == 'Resolved Grievance' ?
                                                                            <td className="text-center">
                                                                                <span className="see_button"
                                                                                    onClick={() => window.open(i.actionReport)}
                                                                                >
                                                                                    <i class="fa-solid fa-file-pdf"></i>
                                                                                </span>
                                                                            </td>
                                                                            : screenname == 'Completed Site Visit' ?
                                                                                (i.m_StatusID == 6) ?
                                                                                    <td className="text-center">
                                                                                        {/* <input type="file" /> */}
                                                                                        <span className="upload_button" onClick={() => handleUploadFilePhoto(i, i.t_GrievanceRegistrationID)}>
                                                                                            {/* <i class="fa-solid fa-upload"></i> */}
                                                                                            upload
                                                                                        </span>

                                                                                    </td>
                                                                                    :
                                                                                    <td className="text-center"> - </td>
                                                                                :
                                                                                <td className="text-center">
                                                                                    {/* <input type="file" /> */}
                                                                                    <span className="upload_button" onClick={() => handleUploadFilePhoto(i, i.t_GrievanceRegistrationID)}>
                                                                                        {/* <i class="fa-solid fa-upload"></i> */}
                                                                                        upload
                                                                                    </span>

                                                                                </td>

                                                                    }


                                                                </tr>
                                                            )
                                                        })
                                                        : <>Record Not Found</>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {TableData && TableData.length > 0 &&
                                        <Pegination
                                            PerPageCount={PerPageCount}
                                            TotalCount={TableData[0].totalCount}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            CurrentPage={CurrentPage}
                                            setCurrentPage={setCurrentPage}
                                            IsClear={IsClear}
                                        />
                                    }
                                </div>
                            </div>
                        </>
                    </div>
                    <Footer />
                </div>
            </div>

            {
                ShowViewPopup &&
                <ViewPopup
                    handleShowView={handleShowView}
                    Title='View'
                    // handleDeleteData={handleDeleteData}
                    ModalData={ModalData}
                />
            }
            {
                ShowUploadFilePopup &&
                <UploadReport
                    handleUploadFilePhoto={handleUploadFilePhoto}
                    Title='Upload Report'
                    ModalData={ModalData}
                    TableData={TableData}
                    handleIsPost={handleIsPost}
                />
            }
            {
                showImagePopup ?
                    <ViewURL
                        // Heading={ImageHeading}
                        setshowImagePopup={setshowImagePopup}
                        ImagePopUpData={ImagePopUpData}

                    />
                    : ''


            }
            {/* <Footer /> */}
        </>
    )
}