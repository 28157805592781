import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Header } from "../../../Header/Header"
import { SideBar } from "../../../SideBar/SideBar"
import { Footer } from "../../../../Footer/Footer"
import ViewPopup from "../../../CommonPopUp/ViewPopup"
import DirectorViewFeedbackPopup from "../DirectorViewFeedbackPopup/DirectorViewFeedbackPopup"
import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../../../../Helper/Context/context"
import { ReceivedResolvedGrievanceGetAPI } from "../../../../../Helper/Redux/DirectorDashboardSlice/ReceivedResolvedGrievanceSlice"
import { DistrictDDL } from "../../../../../Components/CommonDDL/DistrictDDL"
import { GenderDDL } from "../../../../../Components/CommonDDL/GenderDDL"
import moment from "moment"
import { t } from "i18next"




export const DirectorCitizenFeedbackGrid = () => {

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID, IndicatorGrievanceAreaID } = userDetails

    const [FeedbackShowViewPopup, setFeedbackShowViewPopup] = useState(false)

    const [ShowConfirmationPopup, setShowConfirmationPopup] = useState(false)
    const [ShowViewPopup, setShowViewPopup] = useState(false)

    const [DocumentUploadViewPopup, setDocumentUploadViewPopup] = useState(false)
    const [searchparams] = useSearchParams()
    const screenname = searchparams.get('screenname')
    const Flag = searchparams.get('Flag')
    const DDLFinantialYearID = searchparams.get('DDLFinantialYearID')
    const DDLMonthID = searchparams.get('DDLMonthID')
    const screenheading = searchparams.get('screenheading')
    const screensubheading = searchparams.get('screensubheading')
    const RuralAreaID = searchparams.get('RuralAreaID')

    const [IsSearch, setIsSearch] = useState(false)
    const [IsPost, setIsPost] = useState(false)


    // Pagination
    const [IsClear, setIsClear] = useState(false)
    const [PerPageCount, setPerPageCount] = useState(10)
    const [From, setFrom] = useState(1)
    const [To, setTo] = useState(10)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [isopen, setIsOpen] = useState(false);

    const [ModalData, setModalData] = useState({
        Flag: '',
        RowData: ''
    })
    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: sessionStorage.getItem('LanguageChange')
    })
    const [DistrictDDLID, setDistrictDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [GrievanceTypeID, setGrievanceTypeID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [TalukaDDLID, setTalukaDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const handleShowView = (rowData, t_GrievanceRegistrationID) => {
        setShowViewPopup(!ShowViewPopup)
        setModalData({
            Flag: '',
            RowData: rowData ? rowData : '',
            t_GrievanceRegistrationID: t_GrievanceRegistrationID,

        })
    }

    const handleFeedbackShowView = (rowData, t_GrievanceRegistrationID) => {
        setFeedbackShowViewPopup(!FeedbackShowViewPopup)
        setModalData({
            Flag: '',
            RowData: rowData ? rowData : '',
            t_GrievanceRegistrationID: t_GrievanceRegistrationID,
        })
    }

    const handleShowConfirmation = (rowData) => {
        setShowConfirmationPopup(!ShowConfirmationPopup)
        setModalData({
            Flag: '',
            RowData: rowData ? rowData : '',


        })
    }

    const handleSearch = () => {
        setIsSearch(!IsSearch)
    }

    const handleClear = () => {
        setIsClear(!IsClear)

        setDistrictDDLID({
            ...DistrictDDLID,
            ID: 0,
            Label: 'Select...'
        })
        setGrievanceTypeID({
            ...GrievanceTypeID,
            ID: 0,
            Label: 'Select...'
        })
        setTalukaDDLID({
            ...TalukaDDLID,
            ID: 0,
            Label: 'Select...'
        })

    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }

    useEffect(() => {
        const data = {
            M_FinancialYearID: DDLFinantialYearID,
            M_MonthID: DDLMonthID,
            GrievanceDate: '',
            // M_IndicatorGrievanceAreaID: IndicatorGrievanceAreaID,
            M_IndicatorGrievanceAreaID: 0,
            M_DistrictID: DistrictDDLID.ID,
            M_TalukaID: TalukaDDLID.ID,
            M_IndicatorGrievanceTypeID: GrievanceTypeID.ID,
            M_StatusID: 0,
            ShowBy: "All",
            Flag: Flag,
            Language: Language.Label,
            M_UserID: UserID,
            FromTop: From,
            ToTop: To,
            token: token,
        }
        { Language.Label != null && dispatch(ReceivedResolvedGrievanceGetAPI({ data })) }
    }, [Language.Label, DDLFinantialYearID, DDLMonthID, IsClear, IsSearch, IsPost, To, DistrictDDLID.ID, GrievanceTypeID.ID, TalukaDDLID.ID])

    const { TableData, isLoading } = useSelector(state => state.ReceivedResolvedGrievanceGetData)
    console.log("ModalData", TableData)
    return (
        <>
            <Header Language={Language}
                setLanguage={setLanguage} />
            {/* <SideBar active="MainDashboard" /> */}
            <div id="wrapper" className="d-flex justify-content-center">
                <SideBar active="DirectorDashboard" />
                <div className="main-content user_dashboard d-flex flex-column body-scroll px-0 px-lg-3" id='content' >

                    <div className="row px-2 pt-3">
                        <div className="col-12">
                            <h6 className="text-black fw-bold h7 pt-0 pb-2">
                                {screenheading}
                            </h6>
                        </div>
                    </div>

                    <div className="container-fluid px-3 shadow bg-white" style={{ border: '4px solid #dddcdc' }}>
                        <>
                            <div className="row pt-3">
                                <div className="col-12">
                                    <h6 className="text-black fw-bold h7 pt-0 pb-2">
                                        {/* {screensubheading} -  */}
                                        <span className="text-primary">{screenname}</span>
                                    </h6>
                                </div>
                            </div>

                            <div className="row py-3 py-lg-4">
                                <div className="col-12 col-lg-3 py-2 py-lg-0">
                                    <span className="all_lable_text">
                                        {t('DirectorDashboard.DistrictName')}
                                    </span>
                                    <DistrictDDL
                                        DistrictDDLID={DistrictDDLID}
                                        setDistrictDDLID={setDistrictDDLID}
                                        Language={Language}
                                        M_IndicatorGrievanceAreaID={RuralAreaID}
                                        UserID={UserID}
                                    />
                                </div>

                                <div className="col-12 col-lg-3 py-2 py-lg-0">
                                    <span className="all_lable_text">
                                        {t('DirectorDashboard.GrievanceType')}
                                    </span>
                                    <GenderDDL
                                        GenderDDLID={GrievanceTypeID}
                                        setGenderDDLID={setGrievanceTypeID}
                                        Language={Language}
                                        GroupID='3'
                                        UserID={UserID}
                                    />
                                </div>

                                <div className="col-12 col-md-6 col-lg-3 pt-2 mt-3  ">
                                    <button type="button" className="btn btn-outline-dark border-2 mx-2 button-size"
                                    // onClick={handleSearch}
                                    >
                                        Search</button>

                                    <button type="button" className="btn btn-danger border-2 button-size"
                                    // onClick={handleClear}
                                    >Clear</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive p-0">
                                        <table className="table table-bordered w-100">
                                            <thead>
                                                <tr className="bg-dark">
                                                    <th className="">{t('GridHeading.Sr_No')}</th>
                                                    {
                                                        screenname == 'Resolved' &&
                                                        <>
                                                            <th className=" ps-2">{t('DirectorDashboard.ResolvedDate')}</th>
                                                        </>
                                                    }
                                                    {
                                                        screenname == 'Feedback' &&

                                                        <th className=" ps-2">{t('DirectorDashboard.FeedbackDate')}</th>

                                                    }
                                                    <th className=" ps-2">{t('DirectorDashboard.MunicipalCorporation')}</th>
                                                    <th className=" ps-2">{t('DirectorDashboard.DistrictName')}</th>
                                                    <th className=" ps-2">{t('DirectorDashboard.GrievanceType')}</th>
                                                    <th className=" ps-2 text-center">{t('DirectorDashboard.GrievanceDetails')}</th>

                                                    {
                                                        screenname == 'Feedback' &&

                                                        <th className=" ps-2">{t('GrievanceStatus.Feedback')}</th>

                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    TableData && TableData.length > 0 ?
                                                        TableData.map((i, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="w-7">{i?.rowNum}</td>
                                                                    {
                                                                        screenname == 'Resolved' &&
                                                                        <td>{i.actionReportDate ? moment(i.actionReportDate).format('DD-MM-YYYY') : '-'}</td>

                                                                    }

                                                                    {
                                                                        screenname == 'Feedback' &&
                                                                        // <td>{i.actionReportDate ? moment(i.actionReportDate).format('DD-MM-YYYY') : '-'}</td>
                                                                        <td>-</td>
                                                                    }

                                                                    <td>{i.districtName ? i.districtName : '-'}</td>
                                                                    <td></td>
                                                                    <td>{i.m_IndicatorGrievanceType ? i.m_IndicatorGrievanceType : '-'}</td>
                                                                    <td className="text-center w-20">
                                                                        <span className="view_button"
                                                                            data-bs-toggle="tooltip" data-bs-placement="bottom"
                                                                            title="View Grievance Details"
                                                                            onClick={() => handleShowView(i, i.t_GrievanceRegistrationID)}>
                                                                            <i class="fa-solid fa-eye"></i>
                                                                        </span>
                                                                    </td>

                                                                    {
                                                                        screenname == 'Feedback' &&
                                                                        <td> <span className="feedback_button"
                                                                            onClick={() => handleFeedbackShowView(i, i.t_GrievanceRegistrationID)}
                                                                        >Feedback</span></td>

                                                                    }
                                                                </tr>
                                                            )
                                                        })
                                                        : <>Record Not Found</>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                    <Footer />
                </div>
            </div>

            {
                ShowViewPopup &&
                <ViewPopup
                    handleShowView={handleShowView}
                    Title='View'
                    ModalData={ModalData}
                // handleDeleteData={handleDeleteData}
                />
            }

            {
                FeedbackShowViewPopup &&
                <DirectorViewFeedbackPopup
                    handleFeedbackShowView={handleFeedbackShowView}
                    Title='Feedback'
                    ModalData={ModalData}
                // TableData={TableData}
                // handleDeleteData={handleDeleteData}
                />
            }
            {/* <Footer /> */}
        </>
    )
}