

const ConfirmationPopup = (props) => {

    const { handleShowConfirmation, message, Title, handleAcknowledge,Remark,setRemark } = props

    return (<>
        <div className="modal fade show d-flex align-items-center" style={{ display: "block", backgroundColor: "#00000055" }}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title fw-bold text-danger" id="exampleModalLongTitle">{Title}</h5>
                        <button onClick={handleShowConfirmation} type="button" className="close" style={{ marginLeft: "auto" }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body mx-4">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center my-4">
                                <h6 className="text-black">{message}</h6>
                            </div>
                            {
                                Title == "Escalated" ?
                                    <div className=" col-12 pt-1 pt-lg-0 d-flex justify-content-center">

                                        <input
                                            type="text"
                                            className="form-control inputstyle mx-lg-5"
                                            placeholder=""
                                            aria-label="Remark"
                                            aria-describedby="addon-wrapping"
                                            value={Remark}
                                            onChange={(e) => setRemark(e.target.value)}
                                        />
                                    </div>
                                    :
                                    ''
                            }

                            <div className="col-12  d-flex justify-content-center pt-3">
                                <div className="form-group w-80 d-flex justify-content-center">
                                    <button
                                        type='button'
                                        onClick={() => handleAcknowledge()}
                                        className="btn bg-success text-white border-2 mx-2 px-4" data-ktwizard-type="action-next">
                                        Yes
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleShowConfirmation}
                                        className="btn btn-danger border-2" data-ktwizard-type="action-next">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default ConfirmationPopup;