import React, { useEffect, useState } from "react"
import { Footer } from "../pages/Footer/Footer"
import { Header } from "../pages/Private/Header/Header"
import { SideBar } from "../pages/Private/SideBar/SideBar"
import { useSearchParams } from "react-router-dom"
import { useAuthState } from "../Helper/Context/context"
import ConfirmationPopup from "../pages/Private/CommonPopUp/ConfirmationPopup"
import ViewPopup from "../pages/Private/CommonPopUp/ViewPopup"
import { useDispatch, useSelector } from "react-redux"
import { DistrictDDL } from "../Components/CommonDDL/DistrictDDL"
import { GenderDDL } from "../Components/CommonDDL/GenderDDL"
import { t } from "i18next"
import { ReceivedGrievanceAcknowledgePostAPI, ReceivedGrievanceExportGetAPI, ReceivedGrievanceGetAPI } from "../Helper/Redux/DashboardSlice/ReceivedGrievanceSlice"
import { Pegination } from "../Components/Pagination/Pagination"
import { Loading } from "../Helper/Loader/LogoLoader"
import moment from "moment"
import ReceivedGrievancesForwardPopUp from "./ReceivedGrievancesForwardPopUp"
import { TotalReceivedGrievanceExportData } from "./TotalReceivedGrievanceExportData"

export const TotalReceivedGrienavces = () => {

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID, IndicatorGrievanceAreaID, DistrictID } = userDetails

    const [ShowConfirmationPopup, setShowConfirmationPopup] = useState(false)
    const [ShowViewPopup, setShowViewPopup] = useState(false)

    const [searchparams] = useSearchParams()
    const screenname = searchparams.get('screenname')
    const Flag = searchparams.get('Flag')
    const DDLFinantialYearID = searchparams.get('DDLFinantialYearID')
    const DDLMonthID = searchparams.get('DDLMonthID')

    const [Remark, setRemark] = useState('')
    const [ReceivedDate, setReceivedDate] = useState('')
    const [IsSearch, setIsSearch] = useState(false)
    const [IsPost, setIsPost] = useState(false)

    // Pagination
    const [IsClear, setIsClear] = useState(false)
    const [PerPageCount, setPerPageCount] = useState(10)
    const [From, setFrom] = useState(1)
    const [To, setTo] = useState(10)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [isopen, setIsOpen] = useState(false);
    const [ForwardShowPopup, setForwardShowPopup] = React.useState(false)

    const [ModalData, setModalData] = useState({
        Flag: '',
        RowData: ''
    })
    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: sessionStorage.getItem('LanguageChange')
    })

    const [DistrictDDLID, setDistrictDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [GrievanceTypeID, setGrievanceTypeID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const handleShowConfirmation = (rowData, heading) => {
        setShowConfirmationPopup(!ShowConfirmationPopup)
        setModalData({
            Flag: '',
            RowData: rowData ? rowData : '',
            heading: heading

        })
    }

    const handleShowView = (rowData, t_GrievanceRegistrationID) => {

        setShowViewPopup(!ShowViewPopup)
        setModalData({
            Flag: '',
            RowData: rowData ? rowData : '',
            t_GrievanceRegistrationID: t_GrievanceRegistrationID,

        })
    }


    // const handleShowDelete = (rowData) => {
    //     setShowDeletePopup(!ShowDeletePopup)
    //     setModalData({
    //         Flag: '',
    //         RowData: rowData ? rowData : ''
    //     })
    // }

    const handleSearch = () => {
        setIsSearch(!IsSearch)
    }

    const handleClear = () => {
        setIsClear(!IsClear)
        setReceivedDate('')
        setDistrictDDLID({
            ...DistrictDDLID,
            ID: 0,
            Label: 'Select...'
        })
        setGrievanceTypeID({
            ...GrievanceTypeID,
            ID: 0,
            Label: 'Select...'
        })

    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }


    useEffect(() => {
        const data = {
            M_FinancialYearID: DDLFinantialYearID,
            M_MonthID: DDLMonthID,
            GrievanceDate: ReceivedDate,
            M_IndicatorGrievanceAreaID: IndicatorGrievanceAreaID,
            M_DistrictID: 0,
            M_TalukaID: 0,
            M_IndicatorGrievanceTypeID: GrievanceTypeID.ID,
            M_StatusID: 0,
            ShowBy: "All",
            Flag: Flag,
            Language: Language.Label,
            M_UserID: UserID,
            FromTop: From,
            ToTop: To,
            token: token,
        }
        { Language.Label != null && dispatch(ReceivedGrievanceGetAPI({ data })) }

    }, [Language.Label, DDLFinantialYearID, DDLMonthID, IsClear, IsSearch, IsPost, To, DistrictDDLID.ID, GrievanceTypeID.ID])

    const { TableData, isLoading } = useSelector(state => state.ReceivedGrievanceGetData)

    useEffect(() => {
        const data = {
            M_FinancialYearID: DDLFinantialYearID,
            M_MonthID: DDLMonthID,
            GrievanceDate: ReceivedDate,
            M_IndicatorGrievanceAreaID: IndicatorGrievanceAreaID,
            M_DistrictID: 0,
            M_TalukaID: 0,
            M_IndicatorGrievanceTypeID: GrievanceTypeID.ID,
            M_StatusID: 0,
            ShowBy: "All",
            Flag: Flag,
            Language: Language.Label,
            M_UserID: UserID,
            FromTop: From,
            ToTop: '99999',
            token: token,
        }
        { Language.Label != null && dispatch(ReceivedGrievanceExportGetAPI({ data })) }

    }, [Language.Label, DDLFinantialYearID, DDLMonthID, IsClear, IsSearch, IsPost, To, DistrictDDLID.ID, GrievanceTypeID.ID])

    const { ExportTableData, isExportLoading } = useSelector(state => state.ReceivedGrievanceExportGetData)

    const handleAcknowledge = () => {
        const data = {
            T_GrievanceRegistrationID: ModalData.RowData.t_GrievanceRegistrationID,
            Remark: Remark,
            Flag: screenname == 'Total Received Grievance' || screenname == 'Acknowledge Pending' ? "Acknowledge" : "Escalated",
            token: token,
            M_UserID: UserID,
            handleShowConfirmation: handleShowConfirmation,
            handleIsPost: handleIsPost,
            screenname: screenname

        }
        dispatch(ReceivedGrievanceAcknowledgePostAPI({ data }))
    }

    const { isPostLoading } = useSelector(state => state.ReceivedGrievanceAcknowledgePostData)

    const handleForward = (rowData) => {
        setForwardShowPopup(!ForwardShowPopup)
        setModalData({
            Flag: '',
            RowData: rowData ? rowData : '',
            // heading: heading

        })
    }

    const [ForwardPopup, setForwardPopup] = useState(false)

    

    // console.log("ExportTableData", ExportTableData)

    return (
        <>
            {isLoading && <Loading />}
            <Header Language={Language}
                setLanguage={setLanguage} />
            {/* <SideBar active="MainDashboard" /> */}
            <div id="wrapper" className="d-flex justify-content-center">
                <SideBar active="MainDashboard" />
                <div className="main-content user_dashboard d-flex flex-column body-scroll px-0 px-lg-3" id='content' >
                    <div className="row ">

                        <div className="col-12 " style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span className="text-black fw-bold ps-2 pt-1">
                                {/* {t('DirectorDashboard.Dashboard')} */}
                            </span>
                            {/* <div className="btn-group  " role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autocomplete="off" />
                                <label className="btn btn-outline-danger parrot_green_border text-black RADIUS_LEFT" for="btnradio1"
                                    onClick={() => navigate('/DirectorDashboard')}
                                >
                                    Add Director
                                </label>

                                <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autocomplete="off" checked />
                                <label className="btn btn-outline-danger parrot_green" for="btnradio2"
                                    onClick={() => navigate('/MainDashboard')}>
                                    MOH | CS
                                </label>
                            </div> */}
                        </div>
                    </div>

                    <div className="container-fluid px-3 shadow bg-white" style={{ border: '4px solid #dddcdc' }}>
                        <>
                            <div className="row pt-3">
                                <div className="col-8">
                                    <h6 className="text-black fw-bold h7 pt-0 pb-0 pb-lg-2">
                                        {screenname}
                                    </h6>
                                </div>
                                <div className="col-4 mt-2">
                                    {
                                        isExportLoading ?

                                            <button type="button" className="btn btn-success green-btn-color float-end mr-1 mb-1 ml-1" disabled>
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />Loading</button>
                                            :
                                            //  ExportTableData && ExportTableData.length > 0 &&
                                            <TotalReceivedGrievanceExportData
                                                ExcelData={ExportTableData}
                                                name={screenname}
                                                screenname={screenname}
                                                handleShowView={handleShowView}
                                                ModalData={ModalData}
                                                RoleID={RoleID}
                                            />
                                    }
                                </div>
                            </div>

                            <div className="row py-3 py-lg-4">

                                <div className="col-12 col-lg-3 py-2 py-lg-0">
                                    {
                                        screenname == 'Acknowledge Grievance' ?
                                            <>
                                                <span className="all_lable_text">
                                                    {t('DirectorDashboard.AcknowledgeDate')}
                                                </span>
                                            </>
                                            :
                                            <span className="all_lable_text">
                                                {t('DirectorDashboard.ReceivedDate')}

                                            </span>
                                    }

                                    <input
                                        type="date"
                                        className="form-control my-1"
                                        name="letterSorting"
                                        id="letterSorting"
                                        placeholder=""
                                        value={ReceivedDate}
                                        onChange={(e) => setReceivedDate(e.target.value)}
                                    />
                                </div>

                                {/* <div className="col-12 col-lg-3 py-2 py-lg-0">
                                    <span className="all_lable_text">
                                        {t('GrievanceRegistration.District')}
                                    </span>
                                    <DistrictDDL
                                        DistrictDDLID={DistrictDDLID}
                                        setDistrictDDLID={setDistrictDDLID}
                                        Language={Language}
                                        M_IndicatorGrievanceAreaID={IndicatorGrievanceAreaID}
                                        UserID={UserID}
                                    />
                                </div> */}

                                <div className="col-12 col-lg-3 py-2 py-lg-0">

                                    <span className="all_lable_text">
                                        {t('DirectorDashboard.GrievanceType')}
                                    </span>
                                    <GenderDDL
                                        GenderDDLID={GrievanceTypeID}
                                        setGenderDDLID={setGrievanceTypeID}
                                        Language={Language}
                                        GroupID='3'
                                        UserID={UserID}
                                    />
                                </div>

                                <div className="col-12 col-md-6 col-lg-3 pt-2 mt-3  ">
                                    <button type="button" className="btn btn-outline-dark border-2 mx-2 button-size"
                                        onClick={handleSearch}
                                    >
                                        Search</button>

                                    <button type="button" className="btn btn-danger border-2 button-size"
                                        onClick={handleClear}
                                    >Clear</button>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive p-0">
                                        <table className="table table-bordered w-100">
                                            <thead>
                                                <tr className="bg-dark">
                                                    <th className="">{t('GridHeading.Sr_No')}</th>

                                                    {
                                                        screenname == 'Acknowledge Grievance' ?
                                                            <>
                                                                <th className="">{t('DirectorDashboard.AcknowledgeDate')}</th>
                                                            </>
                                                            :
                                                            <th className="">{t('DirectorDashboard.ReceivedDate')}</th>

                                                    }

                                                    {/* <th className=" ps-2">District Name</th> */}

                                                    {/* {
                                                        RoleID == 6 ? */}
                                                    <>
                                                        <th className=" ps-2">{t('DirectorDashboard.DistrictName')} / {t('DirectorDashboard.MunicipalCorporation')}</th>
                                                        <th className=" ps-2">{t('DirectorDashboard.TalukaName')}</th>
                                                        {/* <th className=" ps-2">Village Name</th> */}
                                                    </>
                                                    {/* :
                                                            <>
                                                                <th className=" ps-2">{t('DirectorDashboard.MunicipalCorporation')}</th>
                                                                <th className=" ps-2">{t('DirectorDashboard.DistrictName')}</th>
                                                            </> */}

                                                    {/* } */}


                                                    <th className=" ps-2">{t('DirectorDashboard.GrievanceType')}</th>
                                                    <th className=" ps-2 text-center">{t('DirectorDashboard.GrievanceDetails')}</th>
                                                    {
                                                        screenname == 'Total Received Grievance' ?
                                                            <th className=" ps-2 text-center">{t('DirectorDashboard.Action')}</th>
                                                            : (screenname == 'Acknowledge Pending') ?
                                                                <th className=" ps-2 text-center">{t('DirectorDashboard.Action')}</th>
                                                                :
                                                                <th className=" ps-2 text-center">{t('DirectorDashboard.Action')}</th>
                                                    }

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    TableData && TableData.length > 0 ?
                                                        TableData.map((i, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="w-7">{i?.rowNum}</td>
                                                                    {
                                                                        screenname == 'Acknowledge Grievance' ?
                                                                            <>
                                                                                <td>{i.acknowledgeDate ? moment(i.acknowledgeDate).format('DD-MM-YYYY') : '-'}</td>
                                                                            </>
                                                                            :
                                                                            <td>{i.transactionDate ? moment(i.transactionDate).format('DD-MM-YYYY') : '-'}</td>

                                                                    }


                                                                    {/* {
                                                                        RoleID == 6 ? */}
                                                                    <>
                                                                        <td>{i?.districtName}</td>
                                                                        <td>{i?.talukaName}</td>
                                                                        {/* <td>{i?.villageName}</td> */}
                                                                    </>
                                                                    {/* :
                                                                            <>
                                                                                <td>{i?.districtName}</td>
                                                                                <td>-</td>
                                                                            </>



                                                                    } */}
                                                                    <td>{i?.m_IndicatorGrievanceType}</td>

                                                                    <td className="text-center">
                                                                        {/* <span className="view_button"
                                                                            onClick={() => handleShowView(i, i.t_GrievanceRegistrationID)}
                                                                        // onClick={() => handleShowConfirmation()}
                                                                        >view</span> */}
                                                                        <span className="view_button"
                                                                            data-bs-toggle="tooltip" data-bs-placement="bottom"
                                                                            title="View Grievance Details"
                                                                            onClick={() => handleShowView(i, i.t_GrievanceRegistrationID)}>
                                                                            <i class="fa-solid fa-eye"></i>
                                                                        </span>
                                                                    </td>

                                                                    {
                                                                        screenname == 'Total Received Grievance' ?
                                                                            (i.m_StatusID == 2) ?
                                                                                <td className="text-center w-20">

                                                                                    <span className="Acknowledge_button"
                                                                                        onClick={() => handleShowConfirmation(i, 'Acknowledge')}
                                                                                    >Acknowledge </span>


                                                                                </td>
                                                                                :
                                                                                <td className="text-center w-20">
                                                                                    -
                                                                                </td>
                                                                            :
                                                                            (screenname == 'Acknowledge Pending') ?
                                                                                <td className="text-center w-20">

                                                                                    <span className="Acknowledge_button"
                                                                                        onClick={() => handleShowConfirmation(i, 'Acknowledge')}
                                                                                    >Acknowledge </span>
                                                                                    {/* <span className="forward_button" title="forward"
                                                                                        onClick={() => handleForward(i)}
                                                                                    >
                                                                                        Forward
                                                                                    </span> */}


                                                                                </td>
                                                                                :
                                                                                (i.m_StatusID == 3) ?
                                                                                    <>
                                                                                        <td className="text-center w-20">
                                                                                            <span className="Escalated_button"
                                                                                                onClick={() => handleShowConfirmation(i, 'Escalated')}
                                                                                            >Escalated </span>
                                                                                            <div className="mt-2">
                                                                                                <span className="forward_button" title="forward"

                                                                                                    onClick={() => handleForward(i)}
                                                                                                >
                                                                                                    Forward
                                                                                                </span>
                                                                                            </div>
                                                                                        </td>
                                                                                    </>
                                                                                    :
                                                                                    <td className="text-center w-20">
                                                                                        <span className=""
                                                                                        // onClick={() => handleShowConfirmation(i, 'Escalated')}
                                                                                        >{i.statusName} </span>
                                                                                    </td>

                                                                    }


                                                                </tr>
                                                            )
                                                        })
                                                        : <>Record Not Found</>
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    {TableData && TableData.length > 0 &&
                                        <Pegination
                                            PerPageCount={PerPageCount}
                                            TotalCount={TableData[0].totalCount}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            CurrentPage={CurrentPage}
                                            setCurrentPage={setCurrentPage}
                                            IsClear={IsClear}
                                        />
                                    }
                                </div>
                            </div>
                        </>
                    </div>
                    <Footer />
                </div>
            </div>
            {/* <Footer /> */}

            {
                ShowConfirmationPopup &&
                <ConfirmationPopup
                    handleShowConfirmation={handleShowConfirmation}
                    Title={`${ModalData.heading}`}
                    message={`Are you sure! Do you want to ${ModalData.heading} ?`}
                    handleAcknowledge={handleAcknowledge}
                    Remark={Remark}
                    setRemark={setRemark}
                />
            }

            {
                ShowViewPopup &&
                <ViewPopup
                    handleShowView={handleShowView}
                    Title='View'
                    ModalData={ModalData}
                // handleDeleteData={handleDeleteData}
                />
            }
            {
                ForwardShowPopup ?
                    <ReceivedGrievancesForwardPopUp
                        handleForward={handleForward}
                        setForwardShowPopup={setForwardShowPopup}
                        handleIsPost={handleIsPost}
                        Flag='ViewFeedBackPopUp'
                        ModalData={ModalData}
                    />
                    : ''
            }
        </>
    )
}