import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { UserForwardSelectDDLAPI } from "../Helper/Redux/DDLSlice/DDLSlice";
import { useAuthState } from "../Helper/Context/context";
import { Loading } from "../Helper/Loader/LogoLoader";
import { ForwardPostAPI } from "../Helper/Redux/DashboardSlice/ReceivedGrievanceSlice";
import ConfirmationPopup from "../pages/Private/CommonPopUp/ConfirmationPopup";

export default function ReceivedGrievancesForwardPopUp(props) {
    const { handleForward, handleIsPost, Feedback, setFeedback, ModalData } = props;

    const dispatch = useDispatch();
    const userDetails = useAuthState();
    const { UserID, token } = userDetails;

    const [ForwardDDLId, setForwardDDLId] = useState({
        valueID: '',
        m_DistrictID: ''
    });
    const [ShowConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [gridData, setgridData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [Remark, setRemark] = useState('');
    const [activeButton, setActiveButton] = useState('5'); 

    const handleShowConfirmation = () => {
        setShowConfirmationPopup(!ShowConfirmationPopup);
    };

    useEffect(() => {
        const data = {
            M_UserID: UserID ? UserID : '0',
            token: token,
            setgridData: setgridData
        };
        dispatch(UserForwardSelectDDLAPI({ data }));
    }, [dispatch, UserID, token]);

    useEffect(() => {
        if (gridData.length > 0) {
            // Filter gridData based on the activeButton
            setFilteredData(
                gridData.filter(item => item.m_RoleID === parseInt(activeButton))
            );
        }
    }, [activeButton, gridData]);

    const { DDLData, isLoading } = useSelector(state => state.UserForwardSelectDDLData);

    const handleCheck = (e, id) => {
        setForwardDDLId({
            valueID: '',
            m_DistrictID: ''
        });

        let tempGridData = [...filteredData];
        if (e.target.value == id) {
            tempGridData.forEach(ele => {
                if (ele.valueID == id) {
                    ele.ischecked = true;
                    setForwardDDLId({
                        valueID: ele.valueID,
                        m_DistrictID: ele.m_DistrictID
                    });
                } else {
                    ele.ischecked = false;
                }
            });
        }
        setFilteredData(tempGridData);
    };

    const handleForwardPost = () => {
        const data = {
            T_GrievanceRegistrationID: ModalData && ModalData.RowData?.t_GrievanceRegistrationID,
            Remark: Remark,
            M_DistrictID: ForwardDDLId?.m_DistrictID,
            To_M_UserID: ForwardDDLId?.valueID,
            From_M_UserID: UserID,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleSubmit
        };
        dispatch(ForwardPostAPI({ data }));
    };

    const handleSubmit = () => {
        handleIsPost();
        handleForward();
    };

    return (
        <>
            <div className="modal show fade" style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content" id='nested'>
                        <div className="modal-header popheding p-1" id="popupheading">
                            <div className="ml-4">
                                <h5 className='text-center login_text'>
                                    Forward
                                </h5>
                            </div>
                            <button type="button" className="btn-close mr-3 p-1" data-dismiss="modal" aria-label="Close" data-backdrop='static'
                                style={{ color: 'white' }}
                                onClick={handleForward}
                            >
                            </button>
                        </div>

                        {isLoading && <Loading />}

                        <div className="modal-body" style={{ height: "70vh", overflowY: "auto" }}>
                            <div className="btn-group pb-4" role="group" aria-label="Basic outlined example">
                                <button
                                    type="button"
                                    className={`btn ${activeButton === "5" ? "btn-primary" : "btn-outline-primary"}`}
                                    onClick={() => setActiveButton("5")}
                                >
                                    MedicalOfficerHealth(MOH)
                                </button>
                                <button
                                    type="button"
                                    className={`btn ${activeButton === "6" ? "btn-primary" : "btn-outline-primary"}`}
                                    onClick={() => setActiveButton("6")}
                                >
                                    CivilSurgeon(CS)
                                </button>
                            </div>

                            <div className="row col-12">
                                {
                                    filteredData && filteredData.length > 0 && filteredData.map((i, j) =>
                                        <div className="col-6 pt-1" key={j}>
                                            <form>
                                                <input type="radio"
                                                    className="px-3"
                                                    checked={i.ischecked}
                                                    value={i.valueID}
                                                    onChange={(e) => handleCheck(e, i.valueID)}
                                                />
                                                <label htmlFor={i.valueParam} className="ps-2">{i.valueParam}</label>
                                            </form>
                                        </div>
                                    )}
                                {
                                    filteredData && filteredData.length === 0 &&
                                    <text>No Data Found ..</text>
                                }
                            </div>

                            <div className="px-3 py-4">
                                <span className="all_lable_text">
                                    {t('GrievanceRegistration.Remark')}
                                </span>
                                <div className="form-group">
                                    <textarea className="form-control"
                                        id="Feedback"
                                        placeholder="Type Here...."
                                        type='text'
                                        rows="8"
                                        value={Remark ? Remark : ModalData.RowData.feedback}
                                        onChange={(e) => setRemark(e.target.value)}
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button"
                                disabled={ForwardDDLId.valueID === ''}
                                onClick={() => handleShowConfirmation()}
                                className="btn bg-success text-white" data-bs-dismiss="modal">
                                {t('GrievanceStatus.Submit')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {
                ShowConfirmationPopup &&
                <ConfirmationPopup
                    handleShowConfirmation={handleShowConfirmation}
                    Title={`Forward`}
                    message={`Are you sure! Do you want to Forward ?`}
                    handleAcknowledge={handleForwardPost}
                    Remark={Remark}
                    setRemark={setRemark}
                />
            }
        </>
    );
}

