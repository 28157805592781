import { Pie } from "react-chartjs-2";
import { useNavigate } from "react-router-dom"
import { useAuthState } from "../../../../Helper/Context/context";
import { Chart } from "react-google-charts";
import { useEffect, useState } from "react";
import { t } from "i18next";
import ViewPopup from "../../CommonPopUp/ViewPopup";
import { DirectorReceivedGrievancesPieChart } from "./ReceivedGrievance/AllCharts/DirectorReceivedGrievancesPieChart";
// import { data, options } from "../../../../Helper/ChartDeta";

export const DirectorReceivedGrievance = (props) => {
    const { DDLFinantialYearID, DDLMonthID, Countdata, LableName } = props
    const navigate = useNavigate()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails

    // console.log("Lable", LableName)
    // console.log("CountData", Countdata)

    // const [CheckCountData, setCheckCountData] = useState([Countdata])

    const [CheckTrue, setCheckTrue] = useState(false)


    // function zeroTest(element) {
    //     return element === 0;
    // }

    // var allZeros

    // if (Countdata) {
    //     allZeros = CheckCountData.every(zeroTest);
    // }

    // const handleCheckData = () => {
    //     if ((Countdata && Countdata.TotalReceivedGrievanceTotalCount == 0) || (Countdata && Countdata.TotalReceived_MCTotalCount == 0) || (Countdata && Countdata.TotalReceived_RuralTotalCount == 0)) {
    //         setCheckTrue(true)

    //     } else {
    //         setCheckTrue(false)
    //     }

    // }
    // // console.log(Countdata && Countdata.TotalReceivedGrievanceTotalCount,Countdata && Countdata.TotalReceived_MCTotalCount,Countdata && Countdata.TotalReceived_RuralTotalCount)
    // useEffect(() => {
    //     handleCheckData()
    // }, [Countdata])
    const [ModalData, setModalData] = useState({
        Flag: '',
        RowData: ''
    })
    const [ShowViewPopup, setShowViewPopup] = useState(false)
    const handleShowView = () => {

        setShowViewPopup(!ShowViewPopup)
        setModalData({
            Flag: '',
            RowData: '',
        })
    }

    const [selectedOption, setSelectedOption] = useState(null);

    const handleChartClick = (chartWrapper) => {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();

        if (selection && selection.length > 0) {
            const selectedItem = selection[0];
            if (selectedItem) {
                const rowIndex = selectedItem.row;
                const option = chartWrapper.getDataTable().getValue(rowIndex, 0); // Assuming the option is in the first column
                setSelectedOption(option);
                setShowViewPopup(true)

                // Here you can open your popup or modal with the selected option
                // Example: set a state variable to control the visibility of the popup or modal
            }


        }

    };
    // console.log("selectedOption", selectedOption)
    // console.log("selectedOption", CheckTrue)


    const data = [
        ["Task", "Hours per Day"],
        // [LableName && LableName.TotalReceivedGrievanceTotalCount, Countdata && Countdata.TotalReceivedGrievanceTotalCount],
        [LableName && LableName.TotalReceived_MCTotalCount, Countdata && Countdata.TotalReceived_MCTotalCount],
        [LableName && LableName.TotalReceived_RuralTotalCount, Countdata && Countdata.TotalReceived_RuralTotalCount],

    ];

    const options = {
        // title: "My Daily Activities",
        is3D: true,
        legend: 'none'
    };


    return (
        <>
            <div className="bg-white px-3 rounded">


                <div className="row py-2 my-2  rounded">
                    {/* <div className="col-12">
                    
                </div> */}
                    <div className="col-12 col-lg-9">
                        <h6 className="text-black fw-bold h7 pt-0 pb-2">
                            {t('DirectorDashboard.ReceivedGrievance')}
                            {/* Received Grievance */}
                        </h6>
                        <div className="row">
                            <div className="col-xl-4 col-md-6 mb-4" >
                                <div className="cursor_pointer purpule_hover  shadow h-100 py-2"
                                    style={{ background: 'rgb(166, 84, 223)', border: '2px solid rgba(143, 5, 239, 0.21)' }}
                                    onClick={() => navigate(`/DirectorReceivedGrievanceGrid?screenname=Total Received Grievance&Flag=TotalReceived&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}
                                >
                                    <div className="px-2 ">
                                        <div className="row no-gutters align-items-center">
                                            <div className="mr-2">
                                                <h6 className="text-xs font-weight-bold  text-white Received_sec_fontsize">
                                                    <img src="assets/img/Director/received.png" className="Director_icon icon_bg_blue mb-2" alt="" /> <br />
                                                    {/* Total Received Grievance */}
                                                    {t('DirectorDashboard.TotalReceivedGrievance')}
                                                </h6>

                                                <h4 className=" mb-0 font-weight-bold text-white ">{Countdata.TotalReceivedGrievanceTotalCount ? Countdata.TotalReceivedGrievanceTotalCount : '0'}</h4>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6 mb-4" >
                                <div className="cursor_pointer Orange_hover  shadow h-100 py-2"
                                    style={{ background: 'rgb(252,135,32)', border: '2px solid rgba(183, 89, 5, 0.29)' }}
                                    onClick={() => navigate(`/DirectorReceivedGrievanceGrid?screenheading=Received Grievance&screenname=Municipal Corporation&Flag=TotalReceived_MC&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}
                                >
                                    <div className="px-2 ">
                                        <div className="row no-gutters align-items-center">
                                            <div className="mr-2">
                                                <h6 className="text-xs font-weight-bold text-white  Received_sec_fontsize">
                                                    <img src="assets/img/Director/corporation.png" className="Director_icon icon_bg_blue   mb-2" alt="" /> <br />
                                                    {t('DirectorDashboard.MunicipalCorporation')}
                                                    {/* Municipal Corporation */}
                                                </h6>
                                                <h4 className=" mb-0 font-weight-bold text-white ">{Countdata.TotalReceived_MCTotalCount ? Countdata.TotalReceived_MCTotalCount : '0'}</h4>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6 mb-4" >
                                <div className="cursor_pointer green_hover  shadow h-100 py-2"
                                    style={{ background: '#508aff', border: '2px solid rgba(87 123 197)' }}
                                    onClick={() => navigate(`/DirectorReceivedGrievanceGrid?screenheading=Received Grievance&screenname=Rural Area&Flag=TotalReceived_Rural&DDLFinantialYearID=${DDLFinantialYearID.ID}&DDLMonthID=${DDLMonthID.ID}`)}
                                >
                                    <div className="px-2 ">
                                        <div className="row no-gutters align-items-center">
                                            <div className="mr-2">
                                                <h6 className="text-xs font-weight-bold text-white  Received_sec_fontsize">

                                                    <img src="assets/img/Director/Rural.png" className="Director_icon icon_bg_blue  mb-2" alt="" /> <br />
                                                    {t('DirectorDashboard.RuralArea')}
                                                    {/* Rural Area */}
                                                </h6>

                                                <h4 className=" mb-0 font-weight-bold text-white ">{Countdata.TotalReceived_RuralTotalCount ? Countdata.TotalReceived_RuralTotalCount : '0'}</h4>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-xl-4 col-md-6 mb-4">
                                <div className="cursor_pointer card_design_pink shadow h-100 py-2"
                                    style={{ background: 'rgb(252,135,32)', border: '2px solid rgba(183, 89, 5, 0.29)' }}
                                    onClick={() => navigate('/DirectorReceivedGrievanceGrid?screenheading=Received Grievance&screenname=Municipal Corporation&screensubheading=')}
                                >
                                    <div className="card-body p-1">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-white mb-1">
                                                   
                                                    Municipal Corporation
                                                </div>
                                                <div className="h3 mb-0 pt-2 font-weight-bold  text-white ">230</div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="col-xl-4 col-md-6 mb-4 ">
                                <div className="card_design_orange cursor_pointer shadow h-100 py-2"
                                    style={{ background: 'rgb(255, 93, 148)', border: '2px solid rgba(241, 0, 82, 0.19)' }}
                                    onClick={() => navigate('/DirectorReceivedGrievanceGrid?screenheading=Received Grievance&screenname=Rural Area&screensubheading=')}
                                >
                                    <div className="card-body p-1">
                                       
                                        <div className="col mr-2">
                                            <div className="text-xs  font-weight-bold text-white mb-1">
                                                
                                                Rural  Area
                                            </div>
                                            <div className="h3 mb-0 pt-2 font-weight-bold  card_heading_orange text-white">400</div>
                                        </div>
                    
                                        
                                    </div>
                                </div>
                            </div> */}
                        </div>

                    </div>
                    <div className="col-12 col-lg-3">
                        <DirectorReceivedGrievancesPieChart
                            DDLFinantialYearID={DDLFinantialYearID}
                            DDLMonthID={DDLMonthID}
                            Countdata={Countdata}
                            LableName={LableName} />
                        {/* <Chart
                            chartType="PieChart"
                            data={data}
                            options={options}
                            width={"100%"}
                            height={"200px"}
                            chartEvents={[
                                {
                                    eventName: 'select',
                                    callback: ({ chartWrapper }) => handleChartClick(chartWrapper)
                                }
                            ]}

                        /> */}
                    </div>
                </div>
            </div>
            {
                ShowViewPopup &&
                <ViewPopup
                    handleShowView={handleShowView}
                    Title='View'
                    ModalData={ModalData}

                />
            }
        </>
    )
}