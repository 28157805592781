

import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from 'moment'
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useAuthState } from "../../../../../Helper/Context/context"

export const DirectorReceivedGrievanceGridExportData = (props) => {
    const { ExcelData, name, screenname, ModalData, handleShowView } = props

    // const totalCount = ExcelData && ExcelData.table && ExcelData.table.reduce((total, item) => {
    //     return total + +(item.totalPenalty)
    // }, 0)
    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID, IndicatorGrievanceAreaID } = userDetails

    const { t } = useTranslation()


    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-ExportPreventiveWorkReport"
                className="download-table-xls-button btn btn btn-primary float-end mr-1 mb-1 ml-1"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black', }}
            />


            <table id="table-to-ExportPreventiveWorkReport" style={{ display: 'none', width: '100%' }}>

                <h5><span>&emsp;</span></h5>


                {/* <h4 style={{ textAlign: 'center', fontSize: 16 }}>PCMC-Garden</h4>
                <h4 style={{ textAlign: 'center', fontSize: 16 }}>Report Name : </h4> */}

                <tr><td></td></tr>
               
                <tbody>
                    {
                        ExcelData && ExcelData.length > 0 ? ExcelData.map((i, index,) => (
                            <div >
                                <tr><td className="w-7" colSpan={4} style={{ fontWeight: "bold", textAlign: "center", border: "1px solid black", backgroundColor: '#495057', color: "#fff", borderBottom: "none" }}>{t('GridHeading.GrievanceNo')} : {i?.rowNum}</td></tr>
                                <tr key={index} style={{ textAlign: "center", border: "1px solid black", backgroundColor: '#495057', color: "#fff", }}>

                                    <td style={{ fontWeight: "bold" }}>{t('DirectorDashboard.ReceivedDate')} : {i.transactionDate ? moment(i.transactionDate).format('DD-MM-YYYY') : '-'}</td>

                                    {
                                        screenname == 'Rural Area' ?
                                            <>
                                                <td style={{ fontWeight: "bold" }}>{t('DirectorDashboard.DistrictName')} : {i.districtName ? i.districtName : '-'}</td>
                                                <td style={{ fontWeight: "bold" }}>{t('DirectorDashboard.TalukaName')} : {i.talukaName ? i.talukaName : '-'}</td>
                                                {/* <td>{i.villageName ? i.villageName : '-'}</td> */}
                                            </>
                                            :
                                            <>
                                                <td style={{ fontWeight: "bold" }}>{t('DirectorDashboard.MunicipalCorporation')} : {i.districtName ? i.districtName : '-'}</td>
                                                <td style={{ fontWeight: "bold" }}>{t('DirectorDashboard.DistrictName')} : {'-'}</td>
                                            </>
                                    }

                                    <td style={{ fontWeight: "bold" }}>{t('DirectorDashboard.GrievanceType')} : {i.m_IndicatorGrievanceType ? i.m_IndicatorGrievanceType : '-'}</td>

                                </tr>
                                <tr><td></td></tr>
                                <tr style={{ backgroundColor: '#6b6868', color: "#fff", }}><td colSpan={4} className=" ps-2" style={{ fontWeight: "bold" }}>Grievance Area Details</td></tr>
                                <tr>
                                    <th className="">Grievance Area : </th>
                                    <th colSpan={3} style={{textAlign:"left"}}>{i.m_IndicatorGrievanceArea ? i.m_IndicatorGrievanceArea : '-'}</th>
                                </tr>
                                <tr>
                                    <th className="">Center Name :</th>
                                    <th colSpan={3} style={{textAlign:"left"}}> {i.centerName ? i.centerName : '-'}</th>
                                </tr>
                                <tr>
                                    <th className="">District :</th>
                                    <th colSpan={3} style={{textAlign:"left"}}> {i.districtName ? i.districtName : '-'}</th>
                                </tr>
                                {
                                    RoleID == '6' ?
                                        <>
                                            <tr>
                                                <th className="">Taluka :</th>
                                                <th colSpan={3} style={{textAlign:"left"}}> {i.talukaName ? i.talukaName : '-'}</th>
                                            </tr>
                                            <tr>
                                                <th className="">Village :</th>
                                                <th colSpan={3} style={{textAlign:"left"}}> {i.villageName ? i.villageName : '-'}</th>
                                            </tr>
                                        </>
                                        :
                                        <></>
                                }

                                <tr>
                                    <th className="">Area Name :</th>
                                    <th colSpan={3} style={{textAlign:"left"}}> {i.areaName ? i.areaName : '-'}</th>
                                </tr>
                                <tr>
                                    <th className="">Area Address :</th>
                                    <th colSpan={3} style={{textAlign:"left"}}> {i.areaAdress ? i.areaAdress : '-'}</th>
                                </tr>
                                <tr style={{ backgroundColor: '#6b6868', color: "#fff", }}><td colSpan={4} className=" ps-2" style={{ fontWeight: "bold" }}>Hospital Details</td></tr>
                                <tr>
                                    <th className="">Hospital Name :</th>
                                    <th colSpan={3} style={{textAlign:"left"}}> {i.hospitalName ? i.hospitalName : '-'}</th>
                                </tr>
                                <tr>
                                    <th className="">Hospital Address :</th>
                                    <th colSpan={3} style={{textAlign:"left"}}> {i.hospitalAddress ? i.hospitalAddress : '-'}</th>
                                </tr>
                                <tr>
                                    <th className="">Doctor Name :</th>
                                    <th colSpan={3} style={{textAlign:"left"}}> {i.doctorName ? i.doctorName : '-'}</th>
                                </tr>
                                <tr>
                                    <th className="">Doctor Address :</th>
                                    <th colSpan={3} style={{textAlign:"left"}}> {i.doctorAddress ? i.doctorAddress : '-'}</th>
                                </tr>
                                <tr>
                                    <th className="">Referred Doctor Name :</th>
                                    <th colSpan={3} style={{textAlign:"left"}}> {i.referredDoctorName ? i.referredDoctorName : '-'}</th>
                                </tr>
                                <tr>
                                    <th className="">Patient Name :</th>
                                    <th colSpan={3} style={{textAlign:"left"}}> {i.patientName ? i.patientName : '-'}</th>
                                </tr>
                                <tr>
                                    <th className="">Patient Address :</th>
                                    <th colSpan={3} style={{textAlign:"left"}}> {i.patientAddress ? i.patientAddress : '-'}</th>
                                </tr>
                                <tr>
                                    <th className="">Van Number :</th>
                                    <th colSpan={3} style={{textAlign:"left"}}> {i.vanNumber ? i.vanNumber : '-'}</th>
                                </tr>
                                <tr style={{ backgroundColor: '#6b6868', color: "#fff", }}><td colSpan={4} className="ps-2" style={{ fontWeight: "bold" }}>Grievance Details</td></tr>
                                <tr>
                                    <th className="">Grievance :</th>
                                    <th colSpan={3} style={{textAlign:"left"}}> {i.m_IndicatorGrievanceType ? i.m_IndicatorGrievanceType : '-'}</th>
                                </tr>
                                <tr>
                                    <th className="">Grievance Details :</th>
                                    <th colSpan={3} style={{textAlign:"left"}}> {i.grievanceDetails ? i.grievanceDetails : '-'}</th>
                                </tr>
                                <tr><td></td></tr>
                                <tr><td></td></tr>
                            </div>
                        )) : <tr>No data</tr>
                    }
                </tbody>
            </table>
        </div>
    )
}