import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL";
import { toastError, toastSuccess, toastUnauthorized } from "../../AlertMessage/AlertMessage";

export const ReceivedResolvedGrievanceGetAPI = createAsyncThunk("ReceivedResolvedGrievanceGet", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        GrievanceDate,
        M_IndicatorGrievanceAreaID,
        M_DistrictID,
        M_TalukaID,
        M_IndicatorGrievanceTypeID,
        M_StatusID,
        ShowBy,
        Flag,
        Language,
        FromTop,
        ToTop,
        M_UserID,
        token
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/Dashboard/Get_DB_T_GrievanceRegistrationStateNodal_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&GrievanceDate=${GrievanceDate}&M_IndicatorGrievanceAreaID=${M_IndicatorGrievanceAreaID}&M_DistrictID=${M_DistrictID}&M_TalukaID=${M_TalukaID}&M_IndicatorGrievanceTypeID=${M_IndicatorGrievanceTypeID}&M_StatusID=${M_StatusID}&ShowBy=${ShowBy}&Flag=${Flag}&Language=${Language}&M_UserID=${M_UserID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastUnauthorized()
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ReceivedResolvedGrievanceGetSlice = createSlice({
    name: "ReceivedResolvedGrievanceGet",
    initialState: {
        isLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ReceivedResolvedGrievanceGetAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ReceivedResolvedGrievanceGetAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(ReceivedResolvedGrievanceGetAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const ReceivedResolvedGrievanceGetReducer = ReceivedResolvedGrievanceGetSlice.reducer

///// Export Data 

export const ReceivedResolvedGrievanceExportDataGetAPI = createAsyncThunk("ReceivedResolvedGrievanceExportDataGet", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        GrievanceDate,
        M_IndicatorGrievanceAreaID,
        M_DistrictID,
        M_TalukaID,
        M_IndicatorGrievanceTypeID,
        M_StatusID,
        ShowBy,
        Flag,
        Language,
        FromTop,
        ToTop,
        M_UserID,
        token
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    return fetch(`${BaseUrl}/Dashboard/Get_DB_T_GrievanceRegistrationStateNodal_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&GrievanceDate=${GrievanceDate}&M_IndicatorGrievanceAreaID=${M_IndicatorGrievanceAreaID}&M_DistrictID=${M_DistrictID}&M_TalukaID=${M_TalukaID}&M_IndicatorGrievanceTypeID=${M_IndicatorGrievanceTypeID}&M_StatusID=${M_StatusID}&ShowBy=${ShowBy}&Flag=${Flag}&Language=${Language}&M_UserID=${M_UserID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => {
            if (res.status == 401) {
                toastUnauthorized()
            }
            if (res.status > 300 && res.status != 401) {
                toastError('Something went wrong')
            }
            return res.json()
        })
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ReceivedResolvedGrievanceExportDataGetSlice = createSlice({
    name: "ReceivedResolvedGrievanceExportDataGet",
    initialState: {
        isExportLoading: false,
        ExportTableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ReceivedResolvedGrievanceExportDataGetAPI.pending, (state, action) => {
            state.isExportLoading = true;
        });
        builder.addCase(ReceivedResolvedGrievanceExportDataGetAPI.fulfilled, (state, action) => {
            state.isExportLoading = false;
            state.ExportTableData = action.payload;
        });
        builder.addCase(ReceivedResolvedGrievanceExportDataGetAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isExportLoading = false;
            state.isError = true;
            state.ExportTableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const ReceivedResolvedGrievanceExportDataGetReducer = ReceivedResolvedGrievanceExportDataGetSlice.reducer

export const MCRuralReceivedGrievanceAcknowledgePostAPI = createAsyncThunk("MCRuralReceivedGrievanceAcknowledgePost", async ({ data }) => {
    const {
        T_GrievanceRegistrationID,
        Remark,
        Flag,
        M_UserID,
        token,
        handleShowConfirmation,
        handleIsPost,
        screenname,
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const formdata = new FormData();

    formdata.append("T_GrievanceRegistrationID", T_GrievanceRegistrationID);
    formdata.append("Remark", Remark);
    formdata.append("Flag", Flag);
    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
    };

    fetch(`${BaseUrl}/Dashboard/Post_DB_T_GrievanceTransfer_Action`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleShowConfirmation()
                toastSuccess(result.message)
                handleIsPost()
                return result.data
            } else {
                return result
            }
        })

})

const MCRuralReceivedGrievanceAcknowledgePostSlice = createSlice({
    name: "MCRuralReceivedGrievanceAcknowledgePost",
    initialState: {
        isPostLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(MCRuralReceivedGrievanceAcknowledgePostAPI.pending, (state, action) => {
            state.isPostLoading = true;
        });
        builder.addCase(MCRuralReceivedGrievanceAcknowledgePostAPI.fulfilled, (state, action) => {
            state.isPostLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(MCRuralReceivedGrievanceAcknowledgePostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isPostLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const MCRuralReceivedGrievanceAcknowledgePostReducer = MCRuralReceivedGrievanceAcknowledgePostSlice.reducer

//Upload Photo Patch API

export const AcknowledgeGrievanceDocumentUploadActionPatchAPI = createAsyncThunk("AcknowledgeGrievanceDocumentUploadActionPatch", async ({ data }) => {
    const {
        T_GrievanceRegistrationID,
        ActionReportStateNodal,
        ActionRemark,
        M_UserID,
        token,
        handleShowConfirmation,
        handleIsPost,
    } = data

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const formdata = new FormData();
    formdata.append("T_GrievanceRegistrationID", T_GrievanceRegistrationID);
    formdata.append("ActionReportStateNodal", ActionReportStateNodal);
    formdata.append("ActionRemark", ActionRemark);
    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
    };

    fetch(`${BaseUrl}/Dashboard/Patch_DB_T_GrievanceRegistrationWiseActionRemarkStateNodal_Action`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleShowConfirmation()
                toastSuccess(result.message)
                handleIsPost()
                return result.data
            } else {
                return result
            }
        })
})

const AcknowledgeGrievanceDocumentUploadActionPatchSlice = createSlice({
    name: "AcknowledgeGrievanceDocumentUploadActionPatch",
    initialState: {
        isPostLoading: false,
        TableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(AcknowledgeGrievanceDocumentUploadActionPatchAPI.pending, (state, action) => {
            state.isPostLoading = true;
        });
        builder.addCase(AcknowledgeGrievanceDocumentUploadActionPatchAPI.fulfilled, (state, action) => {
            state.isPostLoading = false;
            state.TableData = action.payload;
        });
        builder.addCase(AcknowledgeGrievanceDocumentUploadActionPatchAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isPostLoading = false;
            state.isError = true;
            state.TableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})
export const AcknowledgeGrievanceDocumentUploadActionPatchReducer = AcknowledgeGrievanceDocumentUploadActionPatchSlice.reducer


