import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Header } from "../../Header/Header"
import { SideBar } from "../../SideBar/SideBar"
import { Footer } from "../../../Footer/Footer"
import ViewPopup from "../../CommonPopUp/ViewPopup"
import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../../../Helper/Context/context"
import { Loading } from "../../../../Helper/Loader/LogoLoader"
import { DistrictDDL } from "../../../../Components/CommonDDL/DistrictDDL"
import { GenderDDL } from "../../../../Components/CommonDDL/GenderDDL"
import { ReceivedResolvedGrievanceExportDataGetAPI, ReceivedResolvedGrievanceGetAPI } from "../../../../Helper/Redux/DirectorDashboardSlice/ReceivedResolvedGrievanceSlice"
import { Pegination } from "../../../../Components/Pagination/Pagination"
import { TalukaDDL } from "../../../../Components/CommonDDL/TalukaDDL"
import { t } from "i18next"
import ViewURL from "../../../../Components/ViewURL/ViewURL"
import { DirectorRuralAreaGridExportData } from "./DirectorRuralAreaGridExportData"



export const DirectorRuralAreaGrid = () => {

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID, IndicatorGrievanceAreaID } = userDetails

    const [ShowConfirmationPopup, setShowConfirmationPopup] = useState(false)
    const [ShowViewPopup, setShowViewPopup] = useState(false)

    const [searchparams] = useSearchParams()
    const screenname = searchparams.get('screenname')
    const Flag = searchparams.get('Flag')
    const DDLFinantialYearID = searchparams.get('DDLFinantialYearID')
    const DDLMonthID = searchparams.get('DDLMonthID')
    const screenheading = searchparams.get('screenheading')
    const screensubheading = searchparams.get('screensubheading')
    const MunicipalCorporationID = searchparams.get('MunicipalCorporationID')

    const [IsSearch, setIsSearch] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [ImagePopUpData, setImagePopUpData] = React.useState('')

    // Pagination
    const [IsClear, setIsClear] = useState(false)
    const [PerPageCount, setPerPageCount] = useState(10)
    const [From, setFrom] = useState(1)
    const [To, setTo] = useState(10)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [isopen, setIsOpen] = useState(false);

    const [ModalData, setModalData] = useState({
        Flag: '',
        RowData: ''
    })
    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: sessionStorage.getItem('LanguageChange')
    })
    const [DistrictDDLID, setDistrictDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [GrievanceTypeID, setGrievanceTypeID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [TalukaDDLID, setTalukaDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const handleShowImage = (img) => {
        setshowImagePopup(true)
        setImagePopUpData(img)
    }


    const handleShowView = (rowData, t_GrievanceRegistrationID) => {

        setShowViewPopup(!ShowViewPopup)
        setModalData({
            Flag: '',
            RowData: rowData ? rowData : '',
            t_GrievanceRegistrationID: t_GrievanceRegistrationID,

        })
    }

    const handleSearch = () => {
        setIsSearch(!IsSearch)
    }

    const handleClear = () => {
        setIsClear(!IsClear)

        setDistrictDDLID({
            ...DistrictDDLID,
            ID: 0,
            Label: 'Select...'
        })
        setGrievanceTypeID({
            ...GrievanceTypeID,
            ID: 0,
            Label: 'Select...'
        })
        setTalukaDDLID({
            ...TalukaDDLID,
            ID: 0,
            Label: 'Select...'
        })

    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }

    useEffect(() => {
        const data = {

            M_FinancialYearID: DDLFinantialYearID,
            M_MonthID: DDLMonthID,
            GrievanceDate: '',
            // M_IndicatorGrievanceAreaID: IndicatorGrievanceAreaID,
            M_IndicatorGrievanceAreaID: 0,
            M_DistrictID: DistrictDDLID.ID,
            M_TalukaID: TalukaDDLID.ID,
            M_IndicatorGrievanceTypeID: GrievanceTypeID.ID,
            M_StatusID: 0,
            ShowBy: "All",
            Flag: Flag,
            Language: Language.Label,
            M_UserID: UserID,
            FromTop: From,
            ToTop: To,
            token: token,
        }
        { Language.Label != null && dispatch(ReceivedResolvedGrievanceGetAPI({ data })) }
    }, [Language.Label, DDLFinantialYearID, DDLMonthID, IsClear, IsSearch, IsPost, To, DistrictDDLID.ID, GrievanceTypeID.ID, TalukaDDLID.ID])

    const { TableData, isLoading } = useSelector(state => state.ReceivedResolvedGrievanceGetData)

    useEffect(() => {
        const data = {

            M_FinancialYearID: DDLFinantialYearID,
            M_MonthID: DDLMonthID,
            GrievanceDate: '',
            // M_IndicatorGrievanceAreaID: IndicatorGrievanceAreaID,
            M_IndicatorGrievanceAreaID: 0,
            M_DistrictID: DistrictDDLID.ID,
            M_TalukaID: TalukaDDLID.ID,
            M_IndicatorGrievanceTypeID: GrievanceTypeID.ID,
            M_StatusID: 0,
            ShowBy: "All",
            Flag: Flag,
            Language: Language.Label,
            M_UserID: UserID,
            FromTop: From,
            ToTop: '99999',
            token: token,
        }
        { Language.Label != null && dispatch(ReceivedResolvedGrievanceExportDataGetAPI({ data })) }
    }, [Language.Label, DDLFinantialYearID, DDLMonthID, IsClear, IsSearch, IsPost, To, DistrictDDLID.ID, GrievanceTypeID.ID, TalukaDDLID.ID])

    const { ExportTableData, isExportLoading } = useSelector(state => state.ReceivedResolvedGrievanceExportDataGet)

    return (
        <>
            {isLoading && <Loading />}
            <Header Language={Language}
                setLanguage={setLanguage} />
            {/* <SideBar active="MainDashboard" /> */}
            <div id="wrapper" className="d-flex justify-content-center">
                <SideBar active="DirectorDashboard" />
                <div className="main-content user_dashboard d-flex flex-column body-scroll px-0 px-lg-3" id='content' >

                    <div className="row px-2 pt-3">
                        <div className="col-12">
                            <h6 className="text-black fw-bold h7 pt-0 pb-2">
                                {screenheading}
                            </h6>
                        </div>
                    </div>

                    <div className="container-fluid px-3 shadow bg-white" style={{ border: '4px solid #dddcdc' }}>
                        <>
                            <div className="row pt-3">
                                <div className="col-10">
                                    <h6 className="text-black fw-bold h7 pt-0 pb-2">
                                        {/* {screensubheading} -  */}
                                        {screensubheading} - <span className="text-primary">{screenname}</span>
                                    </h6>
                                </div>
                                <div className="col-2">
                                    {
                                        isExportLoading ?
                                            <button type="button" className="btn btn-primary float-end mr-1 ml-1" disabled>
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />Loading</button>
                                            :
                                            ExportTableData && ExportTableData.length > 0 &&
                                            <DirectorRuralAreaGridExportData
                                                ExcelData={ExportTableData}
                                                name={screenname}
                                                screenname={screenname}
                                                handleShowView={handleShowView}
                                                ModalData={ModalData}
                                            />
                                    }
                                </div>
                            </div>

                            <div className="row py-3 py-lg-4">
                                <div className="col-12 col-lg-3 py-2 py-lg-0">
                                    <span className="all_lable_text">
                                        {t('DirectorDashboard.DistrictName')}
                                    </span>
                                    <DistrictDDL
                                        DistrictDDLID={DistrictDDLID}
                                        setDistrictDDLID={setDistrictDDLID}
                                        Language={Language}
                                        M_IndicatorGrievanceAreaID={MunicipalCorporationID}
                                        UserID={UserID}
                                    />
                                </div>

                                <div className="col-12 col-lg-3 py-2 py-lg-0">
                                    <span className="all_lable_text">
                                        {t('DirectorDashboard.TalukaName')}
                                    </span>
                                    <TalukaDDL
                                        TalukaDDLID={TalukaDDLID}
                                        setTalukaDDLID={setTalukaDDLID}
                                        M_StateID='1'
                                        M_DistrictID={DistrictDDLID.ID}
                                        Language={Language}
                                        UserID={UserID}
                                    />
                                </div>

                                <div className="col-12 col-lg-3 py-2 py-lg-0">
                                    <span className="all_lable_text">
                                        {t('DirectorDashboard.GrievanceType')}
                                    </span>
                                    <GenderDDL
                                        GenderDDLID={GrievanceTypeID}
                                        setGenderDDLID={setGrievanceTypeID}
                                        Language={Language}
                                        GroupID='3'
                                        UserID={UserID}
                                    />
                                </div>

                                <div className="col-12 col-md-6 col-lg-3 pt-2 mt-3  ">
                                    <button type="button" className="btn btn-outline-dark border-2 mx-2 button-size"
                                        onClick={handleSearch}
                                    >
                                        Search</button>

                                    <button type="button" className="btn btn-danger border-2 button-size"
                                        onClick={handleClear}
                                    >Clear</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive p-0">
                                        <table className="table table-bordered w-100">
                                            <thead>
                                                <tr className="bg-dark">
                                                    <th className="">{t('GridHeading.Sr_No')}</th>
                                                    <th className=" ps-2">{t('DirectorDashboard.DistrictName')}</th>
                                                    <th className=" ps-2">{t('DirectorDashboard.TalukaName')}</th>
                                                    {/* <th className=" ps-2">Village name</th> */}
                                                    <th className=" ps-2">{t('DirectorDashboard.GrievanceType')}</th>
                                                    <th className=" ps-2 text-center">{t('DirectorDashboard.GrievanceDetails')}</th>
                                                    {
                                                        screenname == 'Resolved' ?
                                                            <>
                                                                <th className=" text-center ">{t('DirectorDashboard.Photo')}</th>
                                                                <th className=" ps-2 text-center">{t('DirectorDashboard.Report')} </th>
                                                            </>
                                                            :
                                                            <th className=" ps-2 text-center">{t('DirectorDashboard.Status')} </th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    TableData && TableData.length > 0 ?
                                                        TableData.map((i, index) => {
                                                            return (
                                                                <tr key={index}>

                                                                    <td className="w-7">{i?.rowNum}</td>
                                                                    <td>{i.districtName ? i.districtName : '-'}</td>
                                                                    <td>{i.talukaName ? i.talukaName : '-'}</td>
                                                                    {/* <td>{i.villageName ? i.villageName : '-'}</td> */}

                                                                    <td>{i.m_IndicatorGrievanceType ? i.m_IndicatorGrievanceType : '-'}</td>

                                                                    <td className="text-center w-20">
                                                                        <span className="view_button"
                                                                            data-bs-toggle="tooltip" data-bs-placement="bottom"
                                                                            title="View Grievance Details"
                                                                            onClick={() => handleShowView(i, i.t_GrievanceRegistrationID)}>
                                                                            <i class="fa-solid fa-eye"></i>
                                                                        </span>

                                                                    </td>
                                                                    {
                                                                        screenname == 'Resolved' ?
                                                                            <>
                                                                                <td className="text-center">
                                                                                    <span className="open_button"
                                                                                        onClick={() => (
                                                                                            handleShowImage(i.visitPhoto)
                                                                                        )}
                                                                                    >
                                                                                        <i class="fa-solid fa-image"></i>
                                                                                    </span>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <span className="see_button"
                                                                                        onClick={() => window.open(i.actionReport)}
                                                                                    >
                                                                                        <i class="fa-solid fa-file-pdf"></i>
                                                                                    </span>
                                                                                </td>
                                                                            </>
                                                                            :
                                                                            <td>{i.statusName ? i.statusName : '-'}</td>
                                                                    }
                                                                </tr>
                                                            )
                                                        })
                                                        : <>Record Not Found</>
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    {TableData && TableData.length > 0 &&
                                        <Pegination
                                            PerPageCount={PerPageCount}
                                            TotalCount={TableData[0].totalCount}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            CurrentPage={CurrentPage}
                                            setCurrentPage={setCurrentPage}
                                            IsClear={IsClear}
                                        />
                                    }
                                </div>
                            </div>
                        </>
                    </div>
                    <Footer />
                </div>
            </div>

            {
                ShowViewPopup &&
                <ViewPopup
                    handleShowView={handleShowView}
                    Title='View'
                    ModalData={ModalData}
                // handleDeleteData={handleDeleteData}
                />
            }
            {
                showImagePopup ?
                    <ViewURL
                        // Heading={ImageHeading}
                        setshowImagePopup={setshowImagePopup}
                        ImagePopUpData={ImagePopUpData}

                    />
                    : ''


            }
            {/* <Footer /> */}
        </>
    )
}