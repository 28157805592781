import { t } from "i18next"
import { LanguageDDL } from "../../Components/CommonDDL/LanguageDDL"

export const RegistrationHeader = (props) => {
    const { Language, setLanguage } = props

    return ( 
        <>
            <div className='row g-0 px-2 shadow ps-lg-0 py-2 mt-n1 Registraion_header_bg  border position withregister_header align-items-center'>
            <h6 className='heading_text_Wregi d-block d-md-none ps-1'>{t('CitizenRegistration.PublicGrievanceInAamchiMulgiScheme')}</h6>
                <div className='col-1  text-end'>
                    <img src="assets/img/Logo/Logo.png" className='logo_size_Wregi ' alt="" />

                </div>
                <div className='col-6 col-md-10 pt-2 mt-lg-0 ps-5 ps-lg-0 text-start'>
                    <h3 className='heading_text_Wregi d-none d-md-block ps-5'>{t('CitizenRegistration.PublicGrievanceInAamchiMulgiScheme')}</h3>
                </div>
                <div className="col-5 col-md-1 text-end">
                    <LanguageDDL
                        Language={Language}
                        setLanguage={setLanguage}
                    />
                </div>

            </div>
        </>
    )

}